// const baseURl = 'http://localhost:3332';
const baseURl = 'https://reportingportal.gameblitz.in';

const ServiceRevenueapi = `${baseURl}/srevenue/revenueContent?`;
export {ServiceRevenueapi}

const AllRevenueapi = `${baseURl}/revenue/all-revenueContent?`;
export {AllRevenueapi}

const Loginapi = `${baseURl}/auth/login?`;
export {Loginapi}