import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import { AllRevenueapi } from '../api/api';

function AllRevenue() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState(true);
    const [itemOffset, setItemOffset] = useState(0);
    const [service , setService] = useState('Gameoffy');
    const itemsPerPage = 5;

    const getStartAndEndDates = () => {
        const now = new Date();
        const start = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
        const end = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0));
        return { start, end };
    };

    const Onload = async (start = startDate, end = endDate, page = 1) => {
        try {
            if (!start || !end) return;
            const formattedStartDate = start.toISOString().split('T')[0];
            const formattedEndDate = end.toISOString().split('T')[0];
            const response = await axios.get(`${AllRevenueapi}`, {
                params: {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    service: service,
                    page: page,
                }
            });
            if (response.data) {
                const filteredResults = response.data.results
                console.log('filteredResults: ', filteredResults);
                // .slice(0, -1);
                setData(filteredResults);
                setMessage(response.data.results.length === 0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const { start, end } = getStartAndEndDates();
        setStartDate(start);
        setEndDate(end);
        Onload(start, end,service);
    }, []);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    // Calculate cumulative revenue for the entire dataset
    // let cumulativeRevenue = 0;
    // const updatedData = [...data].reverse().map((row) => {
    //     cumulativeRevenue += row.Daily_Revenue;
    //     return { ...row, Total_Revenue: cumulativeRevenue };
    // }).reverse();

    const handelServiceName = (e) => {
        // console.log('e.target.value: ', e.target.value);
        setService(e.target.value);
    }
    // Gameoffy

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    return (
        <>
            

            <div className="mt-[20%] md:mt-[7%] max-w-md mx-auto grid grid-cols-2 gap-4 md:flex md:flex-row justify-center items-center p-2">
                <div className='md:flex gap-3'>
                    <div className="col-span-2 md:col-span-1">
                        <div className="flex flex-col w-full md:w-auto">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="Start Date"
                                className="p-2 border border-gray-300 rounded-md w-full md:w-[180px] text-black"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <div className="flex flex-col w-full md:w-auto">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="End Date"
                                className="p-2 border border-gray-300 rounded-md w-full md:w-[180px] text-black"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                </div>
                <div className='md:flex gap-3'>
                    <div className="col-span-2 md:col-span-1">
                        <div className="w-full md:w-auto">
                            <select onChange={handelServiceName} name="service" id="service" className="border-2 border-gray-300 rounded-lg p-2 w-full md:w-auto focus:outline-none focus:border-blue-500">
                                <option value="" disabled>Select service</option>
                                <option value="Gameoffy" selected>Gameoffy</option>
                                <option value="Gameblitz">Gameblitz</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <button
                            onClick={() => Onload(startDate, endDate)}
                            type="button"
                            className="w-full md:w-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-md px-5 py-2.5 text-center"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto">
                <table className="w-full sm:w-[74%] divide-y divide-gray-200 bg-white shadow-md rounded-lg md:ml-[15%] mt-[3%] ">
                    <thead className="bg-gray-800 text-white ">
                        <tr>
                            <th className="px-12 py-3 text-left text-xs font-medium uppercase tracking-wider">DATE</th>
                            {/* <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Total Subscription</th> */}
                            <th className="px-10 py-3 text-left text-xs font-medium uppercase tracking-wider"> Charged</th>
                            {/* <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Daily Revenue</th> */}
                            <th className="px-10 py-3 text-left text-xs font-medium uppercase tracking-wider">Daily Revenue</th>
                            <th className="px-10 py-3 text-left text-xs font-medium uppercase tracking-wider">Total Revenue</th>
                            <th className="px-10 py-3 text-left text-xs font-medium uppercase tracking-wider">Service Name</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {currentItems?.map((row, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 `}>

                                <td className="px-10 py-4 whitespace-nowrap text-md text-gray-500">{row.formatted_date}</td>
                                <td className="px-14 sm:px-15 py-4 whitespace-nowrap text-md text-gray-500">
                                    {row.total_charged === null ? 0 : row.total_charged}
                                </td>
                                <td className="px-16 py-4 whitespace-nowrap text-md text-gray-500 flex justify-between">{row.revenue}</td>
                                
                                <td className="px-16 py-4 whitespace-nowrap text-md  text-gray-500">{row.total_revenue}</td>
                                <td className="px-16 py-4 whitespace-nowrap text-md  text-gray-500">{row.service}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {message && <div className="text-center text-2xl  text-black mt-10 ml-[7%]">No data to display</div>}
            </div>
            <div className='flex justify-center mt-5 mb-4 '>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center space-x-2"
                    pageClassName="px-3 py-1 cursor-pointer border border-gray-300 rounded"
                    pageLinkClassName="text-blue-600 hover:bg-blue-100"
                    previousClassName="px-3 py-1 cursor-pointer border border-gray-300 rounded"
                    previousLinkClassName="text-blue-600 hover:bg-blue-100"
                    nextClassName="px-3 py-1 cursor-pointer border border-gray-300 rounded"
                    nextLinkClassName="text-blue-600 hover:bg-blue-100"
                    breakClassName="px-3 py-1 cursor-pointer border border-gray-300 rounded"
                    breakLinkClassName="text-gray-600"
                    activeClassName="bg-blue-500 text-white"
                />
            </div>
        </>
    );
}

export default AllRevenue;