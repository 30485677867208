import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './Components/Home';
import Revenue from './Components/Revenue';
import ServiceRevenue from './Components/ServiceRevenueComp';
import Login from './Components/Login';
import { useEffect } from 'react';

function AuthChecker() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <AuthChecker />
        <Routes>
          {/* <Route path='/' element={<Home />}></Route> */}
          <Route path='/' element={<Revenue />}></Route>
          <Route path='/login' element={<Login />}></Route>
          {/* <Route path='/service_revenue' element={<ServiceRevenue />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;